import { SET } from './types';

export const initialState = {
  pageStack: [],
  btnTitle: '',
  pageClickMode: false,
  subTitle: '',
  autoSkip: 0, // in seconds
  genderTitles: {
    title: '',
    subTitle: '',
  },
  predictTitles: {
    title: '',
    buttonTitle: '',
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };

    default:
      return state;
  }
}
