import queryString from 'query-string';
import EvTruck from '@magnus/react-native-evtruck';
import Mutator from '@magnus/react-native-mutator';
import Session from '@wowmaking/react-native-analytics/core/helpers/session';
import DeviceProps from '@magnus/react-native-device-props';
import * as Sentry from '@sentry/browser';

import packageInfo from '../../../package.json';

if (['localhost', '0.0.0.0', '127.0.0.1'].indexOf(window.location.hostname) === -1) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init() {
    DeviceProps.setAppVersion(packageInfo.version);

    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN,
    });

    return Session.init(this).then(() => {
      const path = window.location.pathname;
      const pathParameter = path.slice(1) || 'main';
      const p = queryString.parse(window.location.search);

      this.setUserProperty('testmode', p.testmode);

      Mutator.setAttribution({ source: p.utm_source, campaign_id: p.campaign_id });

      if (p.utm_source) {
        this.setUserProperty('utm_source', p.utm_source);
      }

      if (p.campaign_id) {
        this.setUserProperty('campaign_id', p.campaign_id);
      }

      if (pathParameter) {
        this.setUserProperty('partition', pathParameter);
      }
    });
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params, options) {
    const event = category + '_' + action;

    EvTruck.trackEvent(event, params);

    window.dataLayer?.push({ event, ...params }); // GTM
  },
};
