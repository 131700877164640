import { createAction } from 'redux-actions';
import Attribution from '@wowmaking/web2app';

import Analytics from 'modules/analytics';
import { ADJUST_LINK, ADJUST_TOKEN, } from 'constants/general';

import { init as initRemoteConfig } from '../remote-config/actions';

import { SET_LOADED } from './types';

const setLoaded = createAction(SET_LOADED);

export function load() {
  return function (dispatch) {
    Attribution.init();
    return Analytics.init()
      .then(() => dispatch(initRemoteConfig()))
      .then(() => dispatch(setLoaded()));
  };
}

export function downloadApp() {
  Analytics.trackEvent('install_app', 'click');

  const link = Attribution.createAdjustLink(ADJUST_LINK, ADJUST_TOKEN, { source: 'demo' });

  window.location.href = link;
}
