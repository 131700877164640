import React, {useEffect, Suspense, lazy} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {StyleRoot} from 'radium';

import {downloadApp} from '../store/app/actions';
import Analytics from '../modules/analytics';

import Loading from 'components/loader';

const Main = lazy(() => import('./main'));
const Gender = lazy(() => import('./gender'));
const Predict = lazy(() => import('./predict'));

export default () => {
  const {loaded} = useSelector((state) => state.app);
  const {autoSkip} = useSelector((state) => state.remoteConfig);

  useEffect(() => {
    if (loaded && autoSkip) {
      setTimeout(() => {
        Analytics.trackEvent('redirect', 'action');
        downloadApp();
      }, autoSkip * 1000);
    }
  }, [loaded, autoSkip]);

  return (
    <StyleRoot className="fill-available">
      {!loaded && <Loading/>}
      <Suspense fallback={<Loading/>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main/>}/>
            <Route exact path="/gender" element={<Gender/>}/>
            <Route exact path="/predict" element={<Predict/>}/>
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      </Suspense>
    </StyleRoot>
  );
};
