export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const BLACK_30 = 'rgba(0, 0, 0, 0.3)';
export const TRANSPARENT = 'transparent';
export const BRIGHT_TEAL = '#00eedb';
export const CERULEAN = '#0082df';
export const BLUEBERRY = '#5550a9';
export const BLUEBERRY_20 = 'rgba(24, 4, 60, 0.2)';
export const YELLOW = '#e0e339';
export const GREEN = '#1ad386';
export const BLUE = '#0fccff';
export const BROWN = '#94593f';
export const RED = '#d9193c';
export const ORANGE = '#e79111';
export const PINK = '#ff4ac9';
export const GRAY = '#919294';
export const BEIGE_OLD = '#dfb79a';
export const GOLDEN = '#d1b30d';
export const EMERALD = '#147d84';
export const LIGHT_MAGENTA = '#d00d68';
export const BURGUNDY = '#ab2115';
export const CORAL = '#e3323c';
export const COPPER = '#bd6524';
export const OLIVE = '#aab53a';
export const SILVER = '#bcc2c5';
export const PURPLE = '#8738ec';
export const KHAKI = '#4c6a25';
export const LILAC = '#977ce2';
export const TURQUOISE = '#4ab4c8';
export const MARRON = '#ae3149';
export const COQUELICOT = '#e54926';
export const HONEYDEW = '#cae9b7';
export const CHARCOAL = '#484d4e';
export const BRONZE = '#b57c3e';
export const AZURE = '#3782fe';
export const RED_BERRY = '#721322';

export const DAY_COLORS = {
  1: YELLOW,
  2: GREEN,
  3: BLUE,
  4: BROWN,
  5: WHITE,
  6: RED,
  7: ORANGE,
  8: PINK,
  9: GRAY,
  10: BLACK,
  11: BEIGE_OLD,
  12: GOLDEN,
  13: EMERALD,
  14: LIGHT_MAGENTA,
  15: BURGUNDY,
  16: CORAL,
  17: COPPER,
  18: OLIVE,
  19: SILVER,
  20: PURPLE,
  21: KHAKI,
  22: LILAC,
  23: TURQUOISE,
  24: MARRON,
  25: COQUELICOT,
  26: HONEYDEW,
  27: CHARCOAL,
  28: BRONZE,
  29: AZURE,
  30: RED_BERRY,
};

/* Colors for AstroStellium theme */
export const BEIGE = '#faf6e8';
export const BEIGE_2 = '#f2e6c4';
export const TOUPE = '#d0b894';
export const LIGHT_TAN = '#fae6c4';
export const AQUA = '#14d5c2';
export const TOPAZ = '#19cebb';
export const TEALISH = '#27baa7';
export const DIRTY_BLUE = '#43909e';
export const DUSK_BLUE = '#247583';
export const LIGHT_GREY_BLUE = '#3D7481';
export const DARK_GREY_BLUE = '#2d5668';
export const DARK_BLUE_GREY_2 = '#1e3b48';
export const DARK_BLUE_GREY_050= 'rgba(21, 43, 53, 0.5)';
export const DARK_GREY_BLUE_050 = ('rgba(45, 86, 104, 0.50)');
export const DARK_GREY_BLUE_085 = ('rgba(45, 86, 104, 0.85)');
export const DARK_GREY_BLUE_TRANSPERENT = ('rgba(45, 86, 104, .0)');
export const TEAL_GRADIENT = [BRIGHT_TEAL, TEALISH];
